import templatesPreview from '@/client/assets/images/templatesPreview.png'

const TemplateVariety = () => {
  return (
    <section id="varieties" className="relative bg-[#121211]">
      <div className='mx-auto w-full px-[1rem] xs:px-[2rem] lg:px-[4rem] xl:px-[9rem] py-[4%] lg:py-[6%]'>
        <div className="relative overflow-hidden rounded-lg">
          <div className="absolute inset-0 bg-[url('@/client/assets/images/parabola3D_4x.png')] bg-center bg-no-repeat" style={{ backgroundSize: '50%' }}></div>
          <div className="flex flex-col xs:flex-row justify-between">
            <div className='w-[100%] xs:w-[55%] lg:w-[38%] flex flex-col gap-5 z-10'>
              <h1 className='text-[1.5rem] lg:text-5xl font-semibold text-[#FFFFFF]'>{`Template Variety: Tailor Your Style`}</h1>
              <p className='text-3 lg:text-lg mb-[20px] xs:mb-0 text-[#edebeb]'>{`Our resume builder offers the convenience of selecting from multiple templates, allowing you to explore various designs until you find the perfect fit for showcasing your skills and experience. With a diverse range of professionally crafted templates at your fingertips, you can effortlessly customize your resume to reflect your unique style and personality. Whether you prefer a sleek and modern layout or a more traditional design, our platform ensures that you have the flexibility to create a standout resume that captures the attention of potential employers.`}</p>
            </div>
            <img src={templatesPreview} alt="templatesPreview" className='z-50 max-w-[98%] xs:max-w-[40%] lg:max-w-[100%]' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TemplateVariety